import React, { useEffect } from "react";
import { useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import parseAndFormatDate from "../utils/ParseAndFormatDate";
import titleCase from "../utils/SnakeToTitleCase";
import FormModal from "../FormModal";
import { Button, Form, Tabs, Tab } from "react-bootstrap";
import WhollyOwnedCPPidForm from "./WhollyOwnedApplication/WhollyOwnedCPPidForm";
import WhollyOwnedApplicationApprovalControls from "./WhollyOwnedApplication/WhollyOwnedApplicationApprovalControls";
import WhollyOwnedContractPharmacyApprovals from "./WhollyOwnedApplication/WhollyOwnedContractPharmacyApprovals";
import CompleteWOFormFields from "./CompleteWOFormFields";
import Activities from "../Activities";

const WhollyOwnedApplication = (props) => {
  const [data, setData] = useState(useMemo(() => props.pharmacies, []));

  const [exemptions, setExemptions] = useState(useMemo(() => props.exemptions, []));
  const [showModalHINEdit, setShowModalHINEdit] = useState(false);
  const [modalEditId, setModalEditId] = useState(null);
  const [showModalPidEdit, setShowModalPidEdit] = useState(false);
  const [showModalCompleteWo, setShowModalCompleteWo] = useState(false);

  const [pid, setPid] = useState(null);
  const [hin, setHin] = useState(null);

  const exemptionColumns = useMemo(
    () => [
      {
        Header: "Designated Pharmacy",
        accessor: "name",
        Filter: () => {},
        Cell: (tableProps) => (
          <>
            <div>{titleCase(tableProps.row.original.name)}</div>
            <small className="text-muted">{titleCase(tableProps.row.original.address)}</small>
          </>
        ),
      },
      {
        Header: "Manufacturer",
        accessor: "manufacturer",
        Filter: () => {},
        Cell: (tableProps) => titleCase(tableProps.row.original.manufacturer),
      },
      {
        Header: "Pharmacy ID",
        accessor: "pharmacy_id",
        Filter: () => {},
      },
      {
        Header: "HIN",
        accessor: "health_industry_number",
        Filter: () => {},
      },
      {
        Header: "Network",
        accessor: "kind",
        Filter: () => {},
        Cell: (tableProps) => (
          <span className="badge bg-success fw-normal text-uppercase">
            {tableProps.row.original.kind}
          </span>
        ),
      },
    ],
    [],
  );

  const pharmacyColumns = useMemo(
    () => [
      {
        Header: "Pharmacy",
        accessor: "name",
        Filter: () => {},
        Cell: (tableProps) => (
          <div>
            {tableProps.row.original.name || <i className="text-muted">N/A</i>}
            <div className="text-muted">
              {tableProps.row.original.address ? (
                <small>
                  {tableProps.row.original.address}, {tableProps.row.original.city},{" "}
                  {tableProps.row.original.state}, {tableProps.row.original.zip_code}
                </small>
              ) : null}
            </div>
          </div>
        ),
      },
      {
        Header: "Pharmacy ID",
        accessor: "hrsa_id",
        Filter: () => {},
        Cell: (tableProps) => (
          <>
            {tableProps.row.original.hrsa_id}
            {tableProps.row.original.contracted_pharmacy ? (
              <small className="text-success ms-1">
                <i className="fa fa-check"></i>
              </small>
            ) : tableProps.row.original.hrsa_id ? (
              <small className="text-danger"> (invalid)</small>
            ) : (
              <small className="text-muted">
                <i>None</i>
              </small>
            )}
            {tableProps.row.original.status == null ? (
              <Button
                className="btn btn-secondary btn-sm ms-1"
                onClick={() => {
                  setShowModalPidEdit(true);
                  setPid(tableProps.row.original.hrsa_id);
                  setModalEditId(tableProps.row.original.id);
                }}
              >
                <i className="fa fa-pencil"></i>
              </Button>
            ) : null}
            {tableProps.row.original.geofence_status != "exempt" ? (
              <div>
                <small
                  className={`${
                    tableProps.row.original.within_geofence == true ? "text-success" : "text-danger"
                  }`}
                >
                  {tableProps.row.original.geofence_status}
                </small>
              </div>
            ) : null}
          </>
        ),
      },
      {
        Header: "HIN",
        accessor: "health_industry_number",
        Filter: () => {},
        Cell: (tableProps) => (
          <>
            {tableProps.row.original.health_industry_number ? (
              tableProps.row.original.health_industry_number
            ) : (
              <small className="text-muted">
                <i>Pending valid pharmacy</i>
              </small>
            )}
            {tableProps.row.original.health_industry_number &&
            tableProps.row.original.health_industry_number ==
              tableProps.row.original.contracted_pharmacy?.health_industry_number ? (
              <small className="text-success ms-1">
                <i className="fa fa-check"></i>
              </small>
            ) : null}
            {(tableProps.row.original.contracted_pharmacy &&
              !tableProps.row.original.contracted_pharmacy?.health_industry_number) ||
            tableProps.row.original.health_industry_number ? (
              <Button
                className="btn btn-secondary btn-sm ms-1"
                onClick={() => {
                  setShowModalHINEdit(true);
                  setHin(tableProps.row.original.health_industry_number);
                  setModalEditId(tableProps.row.original.id);
                }}
              >
                <i className="fa fa-pencil"></i>
              </Button>
            ) : null}
            {tableProps.row.original.contracted_pharmacy &&
            tableProps.row.original.contracted_pharmacy?.health_industry_number &&
            tableProps.row.original.health_industry_number !=
              tableProps.row.original.contracted_pharmacy?.health_industry_number ? (
              <div>
                <small className="text-danger">
                  <i>
                    Warning: HIN does not
                    <br />
                    match our records:{" "}
                    {tableProps.row.original.contracted_pharmacy.health_industry_number}
                  </i>
                </small>
              </div>
            ) : null}
          </>
        ),
      },
      {
        Header: "Approvals",
        accessor: "actions",
        Filter: () => {},
        Cell: (tableProps) => {
          return (
            <WhollyOwnedContractPharmacyApprovals
              application_id={props.application.id}
              wocp_id={tableProps.row.original.id}
              contracted_pharmacy={tableProps.row.original.contracted_pharmacy}
              manufacturer={props.application.manufacturer}
              network_statuses={tableProps.row.original.network_statuses}
            />
          );
        },
      },
    ],
    [],
  );

  const entityDetails = () => {
    return (
      <div className="jumbotron p-4 mb-4 bg-body-tertiary rounded-4">
        <div className="card-group card-group-first">
          <div className="card">
            <div className="card-body">
              <h6>Entity:</h6>
              <div>{props.entity.entity_name}</div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6>340B ID:</h6>
              <div>
                <a href={`/entities/${props.entity.id}`}>{props.entity.id_340b}</a>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6>Manufacturer:</h6>
              {titleCase(props.application.manufacturer)}
            </div>
          </div>
        </div>
        <div className="card-group card-group-last">
          <div className="card">
            <div className="card-body">
              <h6>Contact:</h6>
              {props.application.contact_first_name ? (
                <>
                  <div>
                    {props.application.contact_first_name +
                      " " +
                      props.application.contact_last_name}
                    , {props.application.contact_title}
                  </div>
                  <small className="text-muted">{props.application.contact_email}</small>
                </>
              ) : (
                <i className="text-muted">None</i>
              )}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6>Created At:</h6>
              {parseAndFormatDate(props.application.created_at) || "N/A"}
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <h6>Last Updated:</h6>
              {parseAndFormatDate(props.application.updated_at) || "N/A"}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleWOApplicationStatusChange = (status) => {
    if (status === "complete") {
      setShowModalCompleteWo(true);
    } else {
      const url = `/wholly_owned_application/${props.application.id}`;
      fetch(url, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          "X-CSRF-Token": document.querySelector('meta[name="csrf-token"]').getAttribute("content"),
        },
        body: JSON.stringify({ status: status }),
      }).then((result) => {
        window.location.reload();
      });
    }
  };

  return (
    <React.Fragment>
      <div className="pt-3 pb-2 mb-2 row">
        <div className="col-12">
          <h1 className="h2">
            <i className="fa fa-solid fa-file-lines pe-2"></i>
            Wholly Owned Application
          </h1>
          <div className="d-flex align-items-end">
            <WhollyOwnedApplicationApprovalControls
              application={props.application}
              documents={props.documents}
              handleWOApplicationStatusChange={handleWOApplicationStatusChange}
            />
          </div>
        </div>
      </div>
      {entityDetails()}
      <Tabs defaultActiveKey="exemptions" id="wo_tabs" className="mb-3">
        <Tab
          eventKey="exemptions"
          title={
            <>
              <i className="fa fa-clipboard"></i> Exemption Applications{" "}
              <span className="badge bg-light text-dark">{data.length}</span>
            </>
          }
        >
          <CustomTable columns={pharmacyColumns} data={data} />
        </Tab>
        <Tab
          eventKey="pharmacies"
          title={
            <>
              <i className="fa fa-prescription-bottle"></i> Pharmacy Exemptions{" "}
              <span className="badge bg-success">{exemptions.length}</span>
            </>
          }
        >
          <CustomTable columns={exemptionColumns} data={exemptions} />
        </Tab>
        <Tab
          eventKey="activities"
          title={
            <>
              <i className="fa fa-list"></i> Activity
            </>
          }
        >
          <Activities activities_path={props.activities_path} />
        </Tab>
      </Tabs>
      <FormModal
        show={showModalHINEdit}
        title="Update HIN for Pharmacy"
        buttonText="Update HIN"
        buttonType="primary"
        modalId={`edit_hin_modal_${modalEditId}`}
        fields={<PharmacyEditHinFormFields app_id={props.application.id} hin={hin} />}
        method="POST"
        action={`/wholly_owned_contract_pharmacies/${modalEditId}`}
        dismiss={() => {
          setShowModalHINEdit(false);
        }}
      />
      <FormModal
        show={showModalPidEdit}
        title="Update HRSA Pharmacy ID"
        buttonText="Update PID"
        buttonType="primary"
        modalId={`edit_pid_modal_${modalEditId}`}
        fields={
          <WhollyOwnedCPPidForm
            app_id={props.application.id}
            hrsa_id={pid}
            id_340b={props.entity.id_340b}
          />
        }
        method="POST"
        action={`/wholly_owned_contract_pharmacies/${modalEditId}`}
        dismiss={() => {
          setShowModalPidEdit(false);
        }}
      />
      <FormModal
        show={showModalCompleteWo}
        title="Complete Wholly Owned Application"
        buttonText="Complete"
        buttonType="success"
        modalId={`complete_${props.application.id}`}
        fields={<CompleteWOFormFields id_340b={props.entity.id_340b} />}
        action={`/wholly_owned_application/${props.application.id}`}
        method="POST"
        dismiss={() => {
          setShowModalCompleteWo(false);
        }}
      />
    </React.Fragment>
  );
};

const PharmacyEditHinFormFields = (props) => {
  return (
    <React.Fragment>
      {/* Hidden field with controlId "id" */}
      <Form.Group className="mb-0" controlId="id">
        <Form.Control type="hidden" name="app[id]" value={props.app_id} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="hin">
        <Form.Label>Health Identification Number</Form.Label>
        <Form.Control
          required
          name="wholly_owned_contract_pharmacy[health_industry_number]"
          defaultValue={props.hin}
          placeholder="Enter Health Identification Number..."
        />
      </Form.Group>
    </React.Fragment>
  );
};

export default WhollyOwnedApplication;
