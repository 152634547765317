import React from "react";
import { useState } from "react";
import { Form } from "react-bootstrap";

export default function JiraTicket(props) {
  const [validFormat, setValidFormat] = useState(true);

  const validateJiraTicket = (e) => {
    // A Jira issue key is always formatted as <ABC>-<XYZ>.
    // The ABC is the project the issue belongs to, and the XYZ is a numeric sequence.
    const jiraRegex = /\w+\-\d+/;
    const jiraTicketValue = e.target.value;
    if (jiraTicketValue.match(jiraRegex) || jiraTicketValue == "") {
      setValidFormat(true);
    } else {
      setValidFormat(false);
    }
  };

  return (
    <React.Fragment>
      <Form.Group className="mb-3" controlId="jira_ticket">
        <Form.Label>
          Jira Ticket <small className="text-danger">*</small> <small>(ex. CS-1234)</small>
        </Form.Label>
        {validFormat ? null : <small className="text-danger ms-1">Invalid format</small>}
        <Form.Control
          type="text"
          name="jira_ticket"
          placeholder="Enter Jira Ticket..."
          onChange={validateJiraTicket}
        />
      </Form.Group>
    </React.Fragment>
  );
}
