import React from "react";
import titleCase from "../../utils/SnakeToTitleCase";
import statusBadgeClass from "./StatusBadgeClass";

const WhollyOwnedApplicationApprovalControls = (props) => {
  return (
    <>
      <div className="dropdown me-1">
        <div className="btn-group">
          <button
            type="button"
            className={
              "btn btn-" +
              statusBadgeClass(props.application.status) +
              (props.application.status == "complete" ? " disabled" : "")
            }
          >
            {titleCase(props.application.status)}
          </button>
          {props.application.status != "complete" ? (
            <button
              type="button"
              className={
                "btn dropdown-toggle dropdown-toggle-split btn-" +
                statusBadgeClass(props.application.status)
              }
              id="cpApprovalDropdown"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-reference="parent"
            >
              <span className="visually-hidden">Toggle Dropdown</span>
            </button>
          ) : null}
          {props.application.status != "complete" ? (
            <ul className="dropdown-menu" aria-labelledby="cpApprovalDropdown">
              {["in_review", "needs_outreach", "complete"].map((status) =>
                status != props.application.status ? (
                  <li key={status}>
                    <a
                      className="dropdown-item"
                      onClick={() => {
                        props.handleWOApplicationStatusChange(status);
                      }}
                    >
                      {titleCase(status)}
                    </a>
                  </li>
                ) : null,
              )}
            </ul>
          ) : null}
        </div>
      </div>
      <div className="dropdown ms-1">
        <div className="btn-group">
          <button type="button" className="btn btn-primary">
            Downloads
          </button>
          <button
            type="button"
            className="btn btn-primary dropdown-toggle dropdown-toggle-split"
            id="cpApprovalDropdown"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            data-bs-reference="parent"
          >
            <span className="visually-hidden">Toggle Dropdown</span>
          </button>
          <ul className="dropdown-menu" aria-labelledby="cpApprovalDropdown">
            <li key={"wholly_owned_agreement"}>
              <a
                className="dropdown-item"
                href={`/wholly_owned_application/${props.application.id}/download_agreement`}
                title="Wholly Owned Agreement"
              >
                Wholly Owned Agreement
              </a>
            </li>
            {props.documents.map((document) => (
              <li key={document.filename}>
                <a className="dropdown-item" href={document.path}>
                  {document.filename}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

export default WhollyOwnedApplicationApprovalControls;
