import React from "react";
import JiraTicket from "../JiraTicket";

export default function CompleteWOFormFields(props) {
  return (
    <React.Fragment>
      <p>{`Are you sure you want to complete the Wholly Owned Application for ${props.id_340b}?`}</p>
      <JiraTicket />
      <input type="hidden" value="complete" name="status"></input>
      <input type="hidden" value="patch" name="_method"></input>
    </React.Fragment>
  );
}
