import React, { useEffect, useState, useMemo } from "react";
import CustomTable from "../CustomTable";
import { Button, Form } from "react-bootstrap";
import Loading from "../utils/Loading";
import FormModal from "../FormModal";
import Modal from "../Modal";
import EligibilityStatusDetails from "../utils/EligibilityStatusDetails";
import EligibilityStatusSummary from "../utils/EligibilityStatusSummary";
import JiraTicket from "../JiraTicket";
import RecordCount from "../utils/RecordCount";

const Pharmacies = (props) => {
  const [data, setData] = useState(props.pharmacies);
  const [isLoading, setIsLoading] = useState(true);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showDEAModalEdit, setShowDEAModalEdit] = useState(false);
  const [modalEditId, setModalEditId] = useState(null);
  const [showManufacturersModal, setShowManufacturersModal] = useState(false);
  function splitEveryN(str, n) {
    const arr = [];
    if (str) {
      for (let index = 0; index < str.length; index += n) {
        arr.push(str.slice(index, index + n));
      }
    }
    return arr;
  }

  useEffect(() => {
    if (!data) {
      const fetchData = async () => {
        try {
          const response = await fetch(props.pharmacies_path, {
            headers: { Accept: "application/json" },
          });
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          const fetchedData = await response.json();
          setData(fetchedData);
        } catch (error) {
          console.log("Error fetching data: ", error);
        } finally {
          setIsLoading(false);
        }
      };
      if (!data || data.length === 0) {
        fetchData();
      } else {
        setIsLoading(false);
      }
    } else {
      setIsLoading(false);
    }
  }, []);

  const PharmacyHINFormFields = (props) => {
    return (
      <React.Fragment>
        <Form.Control type="hidden" name="pharmacy[id]" value={props.cp_props.id} />
        <Form.Control type="hidden" name="pharmacy[id_340b]" value={props.cp_props.id_340b} />
        <Form.Group className="mb-3" controlId="hin">
          <Form.Label>Health Industry Number</Form.Label>
          <Form.Control
            name="pharmacy[health_industry_number]"
            defaultValue={props.cp_props.health_industry_number}
            placeholder="Enter health industry number..."
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Text className="text-danger">
            Warning: Submitting a blank input form will clear the health industry number for{" "}
            {props.cp_props.id_340b} and PID: {props.cp_props.pharmacy_id} and all associated
            designations.
          </Form.Text>
        </Form.Group>
        <JiraTicket />
      </React.Fragment>
    );
  };

  const PharmacyDEAFormFields = (props) => {
    return (
      <React.Fragment>
        <Form.Control type="hidden" name="pharmacy[id]" value={props.cp_props.id} />
        <Form.Control type="hidden" name="pharmacy[id_340b]" value={props.cp_props.id_340b} />
        <Form.Group className="mb-3" controlId="dea_id">
          <Form.Label>Drug Enforcement Administration</Form.Label>
          <Form.Control
            name="pharmacy[dea_id]"
            defaultValue={props.cp_props.dea_id}
            placeholder="Enter DEA Number..."
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Text className="text-danger">
            Warning: Submitting a blank input form will clear the dea number for{" "}
            {props.cp_props.id_340b} and PID: {props.cp_props.pharmacy_id}.
          </Form.Text>
        </Form.Group>
        <JiraTicket />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: "Pharmacy",
        accessor: "pharmacy_name",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <>
            <div>
              <b>{tableProps.row.original.pharmacy_name}</b>
            </div>
            {tableProps.row.original.pharmacy_address1 ? (
              <small className="text-muted">
                {tableProps.row.original.pharmacy_address1},{" "}
                {tableProps.row.original.pharmacy_address2} {tableProps.row.original.pharmacy_city},{" "}
                {tableProps.row.original.pharmacy_state}, {tableProps.row.original.pharmacy_zip}
              </small>
            ) : null}
          </>
        ),
      },
      {
        Header: "PID",
        accessor: "pharmacy_id",
        filter: "fuzzyText",
      },
      {
        Header: "Manufacturers",
        accessor: "manufacturers",
        Filter: () => {},
        Cell: (tableProps) => (
          <div
            className="d-flex justify-content-center"
            onClick={() => {
              setShowManufacturersModal(true);
              setModalEditId(tableProps.row.original.id);
            }}
          >
            <EligibilityStatusSummary eligibility={props.eligibility} row={tableProps.row} />
          </div>
        ),
      },
      {
        Header: "HIN",
        accessor: "health_industry_number",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <small>
            {tableProps.value || "n/a"}
            <Button
              className="btn border btn-sm bg-light text-muted ms-2"
              onClick={() => {
                setShowModalEdit(true);
                setModalEditId(tableProps.row.original.id);
              }}
            >
              <i className="fa-solid fa-pen"></i>
            </Button>
          </small>
        ),
      },
      {
        Header: "DEA",
        accessor: "dea_id",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <small className="text-nowrap text-muted">
            {tableProps.value || "n/a"}
            <button
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Update DEA Number"
              className="btn btn-sm border bg-light text-muted ms-2"
              onClick={() => {
                setShowDEAModalEdit(true);
                setModalEditId(tableProps.row.original.id);
              }}
            >
              <i className="fa fa-pen" />
            </button>
          </small>
        ),
      },
      {
        Header: "NPI",
        accessor: "npi",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <small>{splitEveryN(tableProps.row.original.npi, 10).join(", ")}</small>
        ),
      },
      {
        Header: "NCPDP",
        accessor: "ncpdp",
        filter: "fuzzyText",
        Cell: (tableProps) => (
          <small>{splitEveryN(tableProps.row.original.ncpdp, 7).join(", ")}</small>
        ),
      },
    ],
    [],
  );

  return (
    <React.Fragment>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          {!props.hide_count ? <RecordCount record_count={data.length} /> : null}
          {data.length > 0 || props.hide_count ? (
            <CustomTable columns={columns} data={data} />
          ) : null}
          <FormModal
            show={showModalEdit}
            title={`${
              data.find((row) => row.id == modalEditId)?.health_industry_number != null
                ? `Update HIN: ${
                    data.find((row) => row.id == modalEditId)?.health_industry_number
                  } for Pharmacy: ${
                    data.find((row) => row.id == modalEditId)?.pharmacy_name +
                    " - " +
                    data.find((row) => row.id == modalEditId)?.pharmacy_id
                  }`
                : `Add HIN for Pharmacy: ${
                    data.find((row) => row.id == modalEditId)?.pharmacy_name +
                    " - " +
                    data.find((row) => row.id == modalEditId)?.pharmacy_id
                  }`
            }`}
            buttonText="Submit"
            buttonType="primary"
            method="POST"
            modalId={`edit_pharmacy_${modalEditId}`}
            fields={<PharmacyHINFormFields cp_props={data.find((row) => row.id == modalEditId)} />}
            action={`/pharmacies/${data.find((row) => row.id == modalEditId)?.id}/update_hin`}
            dismiss={() => {
              setShowModalEdit(false);
            }}
          />

          <FormModal
            show={showDEAModalEdit}
            title={`${
              data.find((row) => row.id == modalEditId)?.dea_id != null
                ? `Update DEA: ${data.find((row) => row.id == modalEditId)?.dea_id} for Pharmacy: ${
                    data.find((row) => row.id == modalEditId)?.pharmacy_name +
                    " - " +
                    data.find((row) => row.id == modalEditId)?.pharmacy_id
                  }`
                : `Add DEA for Pharmacy: ${
                    data.find((row) => row.id == modalEditId)?.pharmacy_name +
                    " - " +
                    data.find((row) => row.id == modalEditId)?.pharmacy_id
                  }`
            }`}
            buttonText="Submit"
            buttonType="primary"
            method="POST"
            modalId={`edit_pharmacy_${modalEditId}`}
            fields={<PharmacyDEAFormFields cp_props={data.find((row) => row.id == modalEditId)} />}
            action={`/pharmacies/${data.find((row) => row.id == modalEditId)?.id}/update_dea`}
            dismiss={() => {
              setShowDEAModalEdit(false);
            }}
          />

          <Modal
            show={showManufacturersModal}
            dynamicWidth={true}
            dynamicHeight={true}
            title={`Manufacturers for: ${
              data.find((row) => row.id == modalEditId)?.pharmacy_name +
              " - " +
              data.find((row) => row.id == modalEditId)?.pharmacy_id
            }`}
            modalId={`manufacturers_modal_${modalEditId}`}
            buttonText="Ok"
            buttonType="primary"
            body={
              <EligibilityStatusDetails
                row={data.find((row) => row.id == modalEditId)?.pharmacy_id}
                eligibility={props.eligibility}
              />
            }
            action={() => {
              setShowManufacturersModal(false);
            }}
            dismiss={() => {
              setShowManufacturersModal(false);
            }}
          />
        </>
      )}
    </React.Fragment>
  );
};

export default Pharmacies;
